import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import "./page-wrapper.css"

import Header from "./Header"
import Footer from "./Footer"

const PageWrapper = ({
  children,
  location,
  isStandalonePage,
  containMeNot,
} = {}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const containerClass = isStandalonePage ? "" : "PageWrapperContent"
  const contentContainerClassNames = containMeNot
    ? ""
    : "container mx-auto py-4 px-4"

  return (
    <div className="min-h-screen flex flex-col">
      {!isStandalonePage && (
        <div className="flex-none">
          <Header
            siteTitle={data.site.siteMetadata.title}
            location={location}
          />
        </div>
      )}
      <div className={`${containerClass}`}>
        <div className={contentContainerClassNames}>
          <main>{children}</main>
        </div>
      </div>
      {!isStandalonePage && (
        <div className="flex-none">
          <Footer />
        </div>
      )}
    </div>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrapper

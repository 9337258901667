import React from "react"

export function Button({ onClick, children, className = "", style = {} }) {
  return (
    <button
      className={`bg-primary hover:bg-primary-dark1 text-white font-bold py-2 px-4 rounded ${className}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  )
}

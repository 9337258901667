export const navLinks = [
  {
    label: "Home",
    pathname: "/",
  },
  {
    label: "Features",
    pathname: "/features",
  },
  {
    label: "Spaced Repetition",
    pathname: "/spaced-repetition",
  },
  // {
  //   label: "Contact Us",
  //   pathname: "/contact-us",
  // },
]
